import React, {useEffect, useState} from "react";
import {grey} from '@mui/material/colors';
import "./App.css";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import MyAppBAR from "./components/pc/header/MyAppBar";
import RegisterOrg from "./components/pc/org_registration/RegisterOrg";
import Restaurants from "./components/pc/restaurants/Restaurants";
import AboutUs from "./components/pc/about_us/AboutUs";
import MobileDrawer from "./components/mobile/header/drawer";
import MobileMain from "./components/mobile/MobileMain";
import MobileFooter from "./components/mobile/MobileFooter";
import Footer from "./components/pc/Footer";
import {Container, createTheme, ThemeProvider} from "@mui/material";
import * as PropTypes from "prop-types";
import {YMInitializer} from "react-yandex-metrika";


const theme = createTheme({

    typography: {
        fontFamily: 'Inter'
    },
    palette: {
        secondary: {
            main: grey[400],
        },
        primary: {
            main: "#5C61E9",
        },
    },
});

function MuiPickersUtilsProvider(props) {
    return null;
}

MuiPickersUtilsProvider.propTypes = {children: PropTypes.node};

function App() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 1150);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <YMInitializer accounts={[87490945]} options={{webvisor: true}} version="2"/>
            {isMobile ? (
                // MOBILE VERSION
                <BrowserRouter>
                    <MobileDrawer/>
                    {/*<Container style={{marginTop: 20}} maxWidth={"lg"}>*/}
                        <MobileMain/>
                    {/*</Container>*/}
                    <MobileFooter/>
                </BrowserRouter>
            ) : (
                // PC VERSION
                <BrowserRouter>
                    <MyAppBAR/>
                    <div id={"mainPage"}>
                        <Routes>
                            <Route default path="/" element={<AboutUs/>}/>
                        </Routes>
                        <Footer/>
                    </div>

                    {/*Hidden route links*/}
                    <div>
                        <Container maxWidth={"lg"}>
                            <Routes>
                                <Route path="/registration" element={<RegisterOrg/>}/>
                                <Route path="/rest" element={<Restaurants/>}/>
                                <Route path="/app" element={<MyAppBAR/>}/>
                            </Routes>
                        </Container>
                    </div>
                </BrowserRouter>
            )}
        </ThemeProvider>
    );
}

export default App;
