import React, {useEffect, useState} from "react";
import {
    Box,
    ButtonBase,
    Checkbox,
    Fab, FormControlLabel, FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TelegramIcon from '@mui/icons-material/Telegram';
import {getRestaurants, newRestaurantRequestEmailSent,} from "../../../actions/Actions";
import {useDispatch, useSelector} from "react-redux";
import {deepOrange, green, grey, red} from "@mui/material/colors";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import PrivatAgreement from "../policies/PrivacyAgreement";
import {Mood, MoodBad} from "@mui/icons-material";

const defaultValues = {
    //Administrator
    name: "",
    last_name: "",
    user_phone: "",
    user_email: "",
    // # ORGANIZATION
    org_name: "",
    domain_name: "",
    inn: "",
    org_mail: "",
    legal_address: "",
    org_phone: "",
    // # Restaurant
    city: "",
    restaurant_name: "",
    restaurant_address: "",
    restaurant_phone: "",
    restaurant_mail: "",
    whatsapp_phone: "",
    instagram_link: "",
    table_count: "",
    privat_agreement: false
};


const schema = yup.object().shape({
    name: yup.string().required("Обязательное поле"),
    user_phone: yup.string().required("Обязательное поле"),
    // user_email: yup.string().email().required(),
    user_email: yup.string().email("Поле заполнено некорректно введите адрес почты в формате: myuser@mymail.ru").required("Обязательное поле"),
    org_name: yup.string().required("Обязательное поле"),
    domain_name: yup.string().required("Обязательное поле"),
    inn: yup.string().required("Обязательное поле"),
    legal_address: yup.string().required("Обязательное поле"),
    city: yup.string().required("Обязательное поле"),
    restaurant_name: yup.string().required("Обязательное поле"),
    restaurant_address: yup.string().required("Обязательное поле"),
    restaurant_phone: yup.string().required("Обязательное поле"),
    restaurant_mail: yup.string().required("Обязательное поле"),
    whatsapp_phone: yup.string().required("Обязательное поле"),
    instagram_link: yup.string().required("Обязательное поле"),
    privat_agreement: yup.boolean().required().oneOf([true], "Чтобы продолжить, примите согласие на обработку персональных данных"),
});

const Form = () => {
    //Load data from api
    const dispatch = useDispatch();

    // Get values from form
    const [formValues, setFormValues] = useState(defaultValues);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    // Form validation
    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema),
    });

    //privat policy
    const [checked, setChecked] = React.useState(false);
    const handleChek = (event) => {
        setChecked(event.target.checked);
        const {name, checked} = event.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const onSubmitHandler = async () => {
        const response = await dispatch(newRestaurantRequestEmailSent({
            message: `
            Администратор
            <hr/>
            <ul>
                <li>
                    Имя: ${formValues['name']}
                </li>
                <li>
                    Телефон: ${formValues['user_phone']}
                </li>
                <li>
                    Почта: ${formValues['user_email']}
                </li>
            </ul>
            Организация
            <hr/>
            <ul>
                <li>
                    Название организации: ${formValues['org_name']}
                </li>
                <li>
                    Имя домена: ${formValues['domain_name']}
                </li>
                <li>
                    ИНН: ${formValues['inn']}
                </li>
                <li>
                    Юридический адрес: ${formValues['legal_address']}
                </li>
            </ul>           
             Ресторан
            <hr/>
            <ul>
                <li>
                   Город: ${formValues['city']}
                </li>
                <li>
                    Название: ${formValues['restaurant_name']}
                </li>
                <li>
                    Адрес: ${formValues['restaurant_address']}
                </li>
                <li>
                    Телефон: ${formValues['restaurant_phone']}
                </li>                
                <li>
                    Почта: ${formValues['restaurant_mail']}
                </li>                
                <li>
                    whatsapp_phone: ${formValues['whatsapp_phone']}
                </li>  
                <li>
                    Cтолы: ${formValues['table_count']}
                </li>              
                <li>
                    instagram_link: ${formValues['instagram_link']}
                </li>
            </ul>          
         `,
            subject: "Заявка на подключение"
        }));
        console.log(response)
        {
            response === 'Message send' &&
            alert('Заявка на подключение создана 🥳🙏 \n' +
                'Мы свяжемся с вами в ближайшее время.')
        }
        {
            response === 'Message send' &&
            setFormValues(defaultValues)
            reset()
        }
        {
            response != 'Message send' &&
            alert('Что пошло не так ⛔🥴\n' +
                'Напишите в службу поддержки https://t.me/boost_boox_support\n' +
                'Или попробуйте отправить заявку еще раз')
        }
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmitHandler)}
            >
                <Grid container spacing={3} alignItems="left" justify="center" direction="row">
                    {/*<Grid item xs={12} md={12}>*/}
                    {/*    <Paper*/}
                    {/*        variant={"outlined"}*/}
                    {/*        style={{*/}
                    {/*            textAlign: "center",*/}
                    {/*            borderRadius: 90,*/}
                    {/*            background: grey[50]*/}
                    {/*        }}>*/}
                    {/*        /!*<Typography style={{*!/*/}
                    {/*        /!*    fontSize: 20,*!/*/}
                    {/*        /!*    fontWeight: "bold"*!/*/}
                    {/*        /!*}} variant={"overline"}>*!/*/}
                    {/*        /!*    boost box*!/*/}
                    {/*        /!*</Typography>*!/*/}
                    {/*        /!*<br/>*!/*/}
                    {/*        <Typography style={{*/}
                    {/*            fontSize: 13,*/}
                    {/*        }} variant={"overline"}>*/}
                    {/*            Создайте заявку на подключение*/}
                    {/*        </Typography>*/}
                    {/*    </Paper>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} md={12}>
                        <Paper
                            variant={"outlined"}
                            style={{
                                textAlign: "center",
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                // marginTop: 10,
                                background: grey[50]
                            }}
                        >
                            <Typography variant={"overline"}>
                                Данные администратора
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('name')}
                                sx={{width: 1000}}
                                id="name-input"
                                name="name"
                                label="Имя"
                                type="text"
                                variant={"standard"}
                                value={formValues.name}
                                onChange={handleInputChange}
                                error={errors.name?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={"Ваше имя как в паспорте"}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.name?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.name?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('user_phone')}
                                sx={{width: 500}}
                                id="user_phone-input"
                                name="user_phone"
                                label="Номер телефона"
                                type="text"
                                variant={"standard"}
                                value={formValues.user_phone}
                                onChange={handleInputChange}
                                error={errors.user_phone?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={"Этот номер телефона будет использоваться как логин для входа в систему"}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.user_phone?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.user_phone?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('user_email')}
                                sx={{width: 500}}
                                id="user_email-input"
                                name="user_email"
                                label="Почта"
                                type="text"
                                variant={"standard"}
                                value={formValues.user_email.replace(/ /g, "")}
                                onChange={handleInputChange}
                                error={errors.user_email?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={"На эту почту будет выслан пароль для входа в систему"}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.user_email?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.user_email?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper
                            variant={"outlined"}
                            style={{
                                textAlign: "center",
                                borderRadius: 10,
                                marginTop: 30,
                                background: grey[50]
                            }}
                        >
                            <Typography variant={"overline"}>
                                Данные организации
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('org_name')}
                                sx={{width: 1000}}
                                id="org_name-input"
                                name="org_name"
                                label="Название организации"
                                type="text"
                                variant={"standard"}
                                value={formValues.org_name}
                                onChange={handleInputChange}
                                error={errors.org_name?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={"Например: boost box cafe"}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.org_name?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.org_name?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('domain_name')}
                                sx={{width: 500}}
                                id="domain_name-input"
                                name="domain_name"
                                label="Имя домена"
                                type="text"
                                variant={"standard"}
                                value={formValues.domain_name}
                                onChange={handleInputChange}
                                error={errors.domain_name?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={`Например: имя домена bb-cafe. Адрес вашего сайта будет: bb-cafe.boost-box.ru`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.domain_name?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.domain_name?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('inn')}
                                sx={{width: 500}}
                                id="inn-input"
                                name="inn"
                                label="ИНН"
                                type="text"
                                variant={"standard"}
                                value={formValues.inn}
                                onChange={handleInputChange}
                                error={errors.inn?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={`ИНН вашей организации`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.inn?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.inn?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('legal_address')}
                                sx={{width: 500}}
                                id="legal_address-input"
                                name="legal_address"
                                label="Юридический адрес"
                                type="text"
                                variant={"standard"}
                                value={formValues.legal_address}
                                onChange={handleInputChange}
                                error={errors.legal_address?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={`Юридический адрес вашей организации`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.legal_address?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.legal_address?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper
                            variant={"outlined"}
                            style={{
                                textAlign: "center",
                                borderRadius: 10,
                                marginTop: 30,
                                background: grey[50]
                            }}
                        >
                            <Typography variant={"overline"}>
                                Данные ресторана
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('city')}
                                sx={{width: 500}}
                                id="city-input"
                                name="city"
                                label="Город"
                                type="text"
                                variant={"standard"}
                                value={formValues.city}
                                onChange={handleInputChange}
                                error={errors.city?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={`Город, в котором находится ресторан`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.city?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.city?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('restaurant_name')}
                                sx={{width: 500}}
                                id="restaurant_name-input"
                                name="restaurant_name"
                                label="Название ресторана"
                                type="text"
                                variant={"standard"}
                                value={formValues.restaurant_name}
                                onChange={handleInputChange}
                                error={errors.restaurant_name?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={`Например: Boost box cafe Якиманка`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.restaurant_name?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.restaurant_name?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('restaurant_address')}
                                sx={{width: 500}}
                                id="restaurant_address-input"
                                name="restaurant_address"
                                label="Адрес"
                                type="text"
                                variant={"standard"}
                                value={formValues.restaurant_address}
                                onChange={handleInputChange}
                                error={errors.restaurant_address?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={`Адрес вашего ресторана`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.restaurant_address?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.restaurant_address?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('restaurant_phone')}
                                sx={{width: 500}}
                                id="restaurant_phone-input"
                                name="restaurant_phone"
                                label="Телефон"
                                type="text"
                                variant={"standard"}
                                value={formValues.restaurant_phone}
                                onChange={handleInputChange}
                                error={errors.restaurant_phone?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={`Этот номер будет размещен на сайте для связи клиентов с вашим рестораном`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.restaurant_phone?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.restaurant_phone?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('restaurant_mail')}
                                sx={{width: 500}}
                                id="restaurant_mail-input"
                                name="restaurant_mail"
                                label="Почта"
                                type="text"
                                variant={"standard"}
                                value={formValues.restaurant_mail}
                                onChange={handleInputChange}
                                error={errors.restaurant_mail?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={`На этот адрес электронной почты будут приходить уведомления о новых заказах`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.restaurant_mail?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.restaurant_mail?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('whatsapp_phone')}
                                sx={{width: 500}}
                                id="whatsapp_phone-input"
                                name="whatsapp_phone"
                                label="Телефон с whatsapp"
                                type="text"
                                variant={"standard"}
                                value={formValues.whatsapp_phone}
                                onChange={handleInputChange}
                                error={errors.whatsapp_phone?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={`На сайте будет размещена ссылка для связи клиентов с вами по WhatsApp`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.whatsapp_phone?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.whatsapp_phone?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('table_count')}
                                sx={{width: 1000}}
                                id="table_count-input"
                                name="table_count"
                                label="Количество столов"
                                type="text"
                                variant={"standard"}
                                value={formValues.table_count}
                                onChange={handleInputChange}
                                error={errors.table_count?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={`Количество столов: укажите диапазон например: 1-20 или перечислите номера столов например: 2,3,4,6,7,9.`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.table_count?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.table_count?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('instagram_link')}
                                sx={{width: 1000}}
                                id="instagram_link-input"
                                name="instagram_link"
                                label="Ссылка на профиль в Instagram"
                                type="text"
                                variant={"standard"}
                                value={formValues.instagram_link}
                                onChange={handleInputChange}
                                error={errors.instagram_link?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                title={`На сайте будет размещена ссылка на ваш аккаунт в Instagram`}>
                                                <ButtonBase style={{borderRadius: 30}}>
                                                    <HelpOutlineIcon style={errors.instagram_link?.message && {
                                                        background: green[900],
                                                        borderRadius: 30,
                                                        color: "white"
                                                    }} fontSize={"small"}/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={errors.instagram_link?.message}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="privat_agreement"
                                    checked={checked} onChange={handleChek}
                                />
                            }
                            {...register('privat_agreement')}
                            id="privat_agreement-input"
                            name="privat_agreement"
                            label={<PrivatAgreement/>}
                            labelPlacement="end"
                            variant={"standard"}
                            value={formValues.privat_agreement}
                            onChange={handleInputChange}
                        />
                        <FormHelperText sx={{color: "red"}}>
                            {errors.privat_agreement?.message}
                        </FormHelperText>
                    </Grid>
                </Grid>

                <div style={{textAlign: "right", marginTop: 30, marginBottom: 20}}>
                    <Fab style={{borderRadius: 10}} variant="extended" color="primary" type="submit">
                        Отправить
                    </Fab>
                </div>
                <Grid style={{marginBottom: 30}} item xs={12} md={12}>
                    <Paper
                        variant={"outlined"}
                        style={{
                            textAlign: "center",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            marginTop: 30,
                            background: grey[50],
                            padding: 15
                        }}
                    >
                        <Typography style={{fontSize: 11, marginTop: 20}} variant={"overline"}>
                            Если у вас возникли проблемы или вы хотите задать вопрос службе поддержки, пожалуйста,
                            напишите нам
                        </Typography>
                        <Fab size={"small"} style={{
                            background: grey[100],
                            marginLeft: 10
                        }}
                             target="_blank"
                             rel="noopener noreferrer"
                             href="https://t.me/boost_boox_support">
                            <TelegramIcon style={{marginRight: 3, color: "#0088cc"}}/>
                        </Fab>
                    </Paper>
                </Grid>
            </form>
        </div>
    );
};
export default Form;