import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {brown, grey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getRestaurants} from "../../../actions/Actions";
import {CardMedia, Grid} from "@mui/material";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper() {
    //Load data from api
    const dispatch = useDispatch();
    const Restaurants = useSelector((state) => state.Restaurants.RestaurantsList);

    useEffect(() => {
        const loadRestaurants = async () => {
            await dispatch(getRestaurants());
        };
        loadRestaurants();
    }, [dispatch]);
    console.log(Restaurants)


    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeRest, setRest] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleStepChange = () => (step) => {
    //     setActiveStep(step);
    // };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Grid container spacing={3}>
            {Restaurants.map((restaurant, index) => (
                <Grid key={index} item xs={12} md={6} sx={{marginTop: 2, maxWidth: 350, flexGrow: 1}}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 30,
                            pl: 2,
                            backgroundColor: grey[100],
                            borderTopRightRadius: 10, borderTopLeftRadius: 10
                        }}
                    >
                        <Typography variant={"overline"} style={{marginTop: 5, marginRight: 5, marginLeft: 5}}>
                            {restaurant.name}
                        </Typography>
                    </div>

                    {/*<div*/}
                    {/*    style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        alignItems: 'center',*/}
                    {/*        height: 25,*/}
                    {/*        pl: 2,*/}
                    {/*        backgroundColor: grey[100]*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Typography*/}
                    {/*        variant={"caption"}*/}
                    {/*        style={{marginRight: 5, marginLeft: 5,}}*/}
                    {/*    >*/}
                    {/*        {restaurant.menu[activeStep].title}*/}
                    {/*    </Typography>*/}
                    {/*</div>*/}

                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {restaurant.menu.map((step, index) => (
                            <div key={step.title}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <CardMedia style={{
                                        height: 0,
                                        paddingTop: "56.25%", // 16:9
                                    }} image={step.photo} title={step.title} />

                                ) : null}
                            </div>
                        ))}
                    </SwipeableViews>
                    <div>
                        <MobileStepper
                            style={{
                                display: 'flex',
                                height: 30,
                                background: grey[100],
                                borderBottomRightRadius: 10,
                                borderBottomLeftRadius: 10
                            }}
                            steps={restaurant.menu.length}
                            position="static"
                            activeStep={activeStep}
                            // nextButton={
                            //     <Button
                            //         size="small"
                            //         onClick={handleNext}
                            //         disabled={activeStep === maxSteps - 1}
                            //     >
                            //         Next
                            //         {theme.direction === 'rtl' ? (
                            //             <KeyboardArrowLeft />
                            //         ) : (
                            //             <KeyboardArrowRight />
                            //         )}
                            //     </Button>
                            // }
                            // backButton={
                            //     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            //         {theme.direction === 'rtl' ? (
                            //             <KeyboardArrowRight />
                            //         ) : (
                            //             <KeyboardArrowLeft />
                            //         )}
                            //         Back
                            //     </Button>
                            // }
                        />
                    </div>
                </Grid>
            ))}
        </Grid>

    );
}

export default SwipeableTextMobileStepper;
