import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Typography from "@mui/material/Typography";
import {ButtonBase, Link, useMediaQuery} from "@mui/material";
import {useTheme} from "@emotion/react";
import {useEffect, useState} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 1150);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div>
            <ButtonBase onClick={handleClickOpen}>
                {
                    isMobile ? (
                        <Typography style={{fontSize: 16, marginTop: 16}}>
                            Персональные данные
                        </Typography>
                    ) : (
                        <Typography style={{fontSize: 18, marginTop: 16}}>
                            Персональные данные
                        </Typography>
                    )
                }
            </ButtonBase>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {/*<DialogTitle>{"Use Google's location service?"}</DialogTitle>*/}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div>
                            <Typography variant={"caption"}>
                                Соглашение на обработку персональных данных
                                <br/>
                                <br/>
                                Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте
                                https://boost-box.ru/ (далее – Сайт) оператором которого является Общество с
                                ограниченной ответственностью «ЭЛЕКТРОЭКО», включая всех лиц, входящих в одну группу с
                                Оператором и лиц, осуществляющих обработку персональных данных по поручению оператора,
                                могут получить данные о Пользователе во время использования им Сайта (включая сервисы,
                                службы, программы или услуги Оператора) и в ходе исполнения любых соглашений и договоров
                                с Пользователем. Согласие Пользователя с Соглашением, выраженное им в рамках отношений с
                                одним из перечисленных лиц, распространяется на все остальные перечисленные лица.
                                Пользователь путем заполнения полей онлайн-заявки (регистрации) на Сайте:
                                <ul>
                                    <li>подтверждает, что указанные им персональные данные принадлежат лично ему;</li>
                                    <li>признает и подтверждает, что он внимательно и в полном объеме ознакомился с
                                        настоящим соглашением и содержащимися в нем условиями обработки его персональных
                                        данных, указываемых им в полях онлайн заявки (регистрации) на сайте;
                                    </li>
                                    <li>признает и подтверждает, что все положения настоящего Соглашения и условия
                                        обработки его персональных данных ему понятны;
                                    </li>
                                    <li>дает согласие на обработку Сайтом предоставляемых персональных данных для
                                        регистрации Пользователя на Сайте;
                                    </li>
                                    <li>выражает согласие с условиями обработки персональных данных без каких-либо
                                        оговорок и ограничений.
                                    </li>
                                </ul>
                                Пользователь дает свое согласие на обработку его персональных данных, а именно
                                совершение действий, предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 №
                                152-ФЗ «О персональных данных», и подтверждает, что, давая такое согласие, он действует
                                свободно, своей волей и в своем интересе. Согласие Пользователя на обработку
                                персональных данных является конкретным, информированным и сознательным.
                                <br/>
                                <br/>
                                Настоящее согласие Пользователя обрабатывается в следующих целях: регистрация
                                Пользователей на Сайте, осуществление агентских услуг, маркетинговых акций,
                                взаимодействие с потенциальными клиентами, аналитическими отчетами, а также поздравлений
                                с днем рождения и других коммуникаций между Оператором и клиентом, и применяется в
                                отношении обработки следующих персональных данных:
                                <ul>
                                    <li>фамилия, имя, отчество;</li>
                                    <li>номер телефона;</li>
                                    <li>адреса электронной почты (E-mail);</li>
                                </ul>
                                Дополнительно на Сайте происходит сбор и обработка обезличенных данных о посетителях (в
                                том числе файлов «cookie») с помощью сервисов интернет-статистики:
                                <ul>
                                    <li>Яндекс.Метрика https://metrika.yandex.ru,</li>
                                    <li>Google Analytics https://analytics.google.com,</li>
                                    <li>Segment.com https://segment.com</li>
                                    <li>социальной сети ВКонтакте https://vk.com.</li>
                                </ul>
                                Пользователь, предоставляет Сайту право осуществлять следующие действия (операции) с
                                персональными данными:
                                <ul>
                                    <li>сбор и накопление;</li>
                                    <li>хранение в течение установленных нормативными документами сроков хранения
                                        отчетности, но не менее трех лет, с момента даты прекращения пользования услуг
                                        Сайта Пользователем;
                                    </li>
                                    <li>уточнение (обновление, изменение);</li>
                                    <li>использование в указанных в настоящем соглашении целях;</li>
                                    <li>уничтожение;</li>
                                    <li>передача третьим лицам с соблюдением мер, обеспечивающих защиту персональных
                                        данных от несанкционированного доступа, а также по требованию суда.
                                    </li>
                                </ul>
                                Безопасность персональных данных обеспечивается путем реализации правовых,
                                организационных и технических мер, необходимых для выполнения в полном объеме требований
                                действующего законодательства в области защиты персональных данных. Сайт обеспечивает
                                сохранность персональных данных и принимает все возможные меры, исключающие доступ к
                                персональным данным неуполномоченных лиц.
                                <br/>
                                <br/>
                                Целевой аудиторией, принимающей решение об оказании нами Услуг, являются
                                совершеннолетние лица, однако могут возникать случаи, когда лицо, не достигшее 18 лет,
                                примет решение ознакомиться с Услугами на нашем Сайте или приобрести соответствующие
                                Услуги. В случае, если мы знаем, что потребителем является лицо, не достигшее 18 лет, мы
                                не будем использовать Персональные данные потребителя в маркетинговых целях, кроме
                                случаев, когда родителями (иными законными представителями) несовершеннолетнего лица
                                заведомо было предоставлено согласие на обработку его/ее Персональных данных.
                                <br/>
                                <br/>
                                Также обращаем внимание на то, что лица, не достигшие определенного возраста, не могут
                                иметь доступ к определенным разделам нашего Сайта. Мы можем использовать Ваши
                                Персональные данные для целей проверки Вашего возраста и применения тех или иных
                                возрастных ограничений.
                                <br/>
                                <br/>
                                Сайт не передает данные о пользователях третьим лицам, если это не связано с улучшением
                                качества работы и предоставлении услуг компанией. Сегодня частичные данные о
                                пользователях передаются в системы аналитики (Яндекс.Метрика https://metrika.yandex.ru,
                                Google Analytics https://analytics.google.com, Segment.com https://segment.com), однако
                                список компаний и сервисов, которые используются для работы, может изменяться. Самая
                                актуальная информация о таких сервисах будет находиться на Сайте. Администрация Сайта
                                оставляет за собой право передачи персональных данных третьим лицам (контрагентам).
                                Указанное согласие действует на весь срок использования Сайта и существования личного
                                кабинета с момента предоставления данных и может быть отозвано Вами путем подачи
                                соответствующего заявления в свободной форме администрации Сайта.
                                <br/>
                                <br/>
                                Отзыв согласия на обработку персональных данных, а также просьба об уточнении данных, их
                                полном или частичном удалении могут быть осуществлены путем направления Пользователем
                                соответствующего распоряжения в простой письменной форме на адрес электронной почты
                                (E-mail) info@boost-box.ru. Кроме этого, Пользователь всегда может ознакомиться с
                                информацией, которую мы о нем храним, написав на этот e-mail. Владелец Сайта имеет право
                                вносить изменения в настоящее Соглашение. При внесении изменений в актуальной редакции
                                указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с
                                момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.
                                <br/>
                                <br/>
                                <br/>
                                Действующая редакция всегда находится на странице по адресу: https://boost-box.ru
                                <br/>
                                <br/>
                                Версия соглашения от 30 мая 2023 года.


                            </Typography>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        ПРОЧИТАНО
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
