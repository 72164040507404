import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://backend.boost-box.ru/backend/api/v1/",
});

const setAuthToken = (token) => axiosInstance.defaults.headers.common["Authorization"] = "Token " + "d70c3779b88a2832c41bfa345cea1bcb6adb6852";

const addDefaultParams = (params) => {
    if (!axiosInstance.defaults.params) axiosInstance.defaults.params = {}
    for (const key of Object.keys(params)) {
        axiosInstance.defaults.params[key] = params[key];
    }
}

const clearDefaults = () => {
    delete axiosInstance.defaults.headers["Authorization"];
    axiosInstance.defaults.params = null;
}


const responseBody = (response) => response.data;

const requests = {
    get: (url, params) => axiosInstance.get(url, params ? { params } : null).then(responseBody),
    post: (url, body) => axiosInstance.post(url, body).then(responseBody),
    uploadFile: (url, file) => axiosInstance.post(url, file, { headers: { "Content-Type": "multipart/form-data" } }),
    put: (url, body) => axiosInstance.put(url, body).then(responseBody),
    del: (url) => axiosInstance.delete(url).then(responseBody),
    patch: (url, data) => axiosInstance.patch(url, data).then(responseBody),
};


const Restaurants = {
    // fixme set to application for new restaurant connection
    list: () => requests.get(`/restaurant/`),
};

const NewRestaurantRequestEmailSent = {
    create: (requestMessage) => requests.post("/email_sent", requestMessage),
};


const bb = {
    setAuthToken,
    addDefaultParams,
    clearDefaults,
    Restaurants,
    NewRestaurantRequestEmailSent
};



export default bb;
