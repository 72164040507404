import {
    GET_RESTAURANTS, NEW_RESTAURANT_REQUEST_EMAIL_SENT,
} from "./types";
import bb from "../components/pc/api/boostBox";


// Restaurants
export const getRestaurants = () => async (dispatch) => {
    try {
        dispatch({ type: GET_RESTAURANTS, payload: await bb.Restaurants.list() });
    } catch (e) {
        console.log(e);
    }
};

//NewRestaurantRequestEmailSent
export const newRestaurantRequestEmailSent = (requestMessage) => async (dispatch) => {
    try {
        const response = await bb.NewRestaurantRequestEmailSent.create({ ...requestMessage });
        dispatch({ type: NEW_RESTAURANT_REQUEST_EMAIL_SENT, payload: requestMessage });
        return response
    } catch (e) {
        console.log(e);
    }
};