import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Chip, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {TimelineDot} from "@mui/lab";

const steps = [
    {'name': 'Оставьте заявку на подключение', 'id': "1"},
    {'name': 'Заполните меню', "id": "2"},
    {'name': 'Ваш сайт готов', "id": "🥳"},
    {'name': 'Распечатайте и разместите QR-код(ы) на столах вашего заведения', "id": "4"},
    {'name': 'Гости делают заказы', "id": "5"},
];

export default function HorizontalLabelPositionBelowStepper() {
    return (
        <Box style={{
            width: '100%',
            marginTop: 60,
            background: "#F5F5F7",
            borderRadius: 24,
            height: 630,
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
        >
            <Container maxWidth={"lg"}>

                <Typography style={{
                    fontSize: 32,
                    marginBottom: 50
                }}
                >
                    Как начать
                </Typography>
                <Stepper activeStep={0} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label.id}>
                            <StepLabel
                                icon={
                                    <TimelineDot color="primary" variant="outlined" style={{
                                        width: 35,
                                        height: 35,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#5C61E9"
                                    }}>
                                        {label.id}
                                    </TimelineDot>
                                }
                            ><Typography style={{
                                fontSize: 18
                            }}
                            >{label.name}</Typography></StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div
                    style={{
                        // width: '100%',
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                    }}
                >
                    {/*<Chip variant={"outlined"} label={"😎"} style={{ padding: 30, borderRadius: 90}}/>*/}
                    <TimelineDot color="primary" variant="outlined" style={{
                        marginLeft: 34,
                        marginTop: 35,
                        width: 35,
                        height: 35,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        😎
                    </TimelineDot>
                    <Typography style={{
                        fontSize: 18,
                        marginLeft: 16
                    }}>
                        Новые заказы будут отображаться в личном
                        <br/>
                        кабинете в разделе «заказы»,
                        <br/>
                        уведомление также будет отправлено
                        на почту,
                        <br/>
                        указанную в заявке на подключение
                    </Typography>
                </div>
            </Container>
        </Box>
    );
}
