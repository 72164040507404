import axios from 'axios';
import React, {useEffect, useState} from "react";
import {
    Box,
    ButtonBase,
    Checkbox,
    Chip,
    Fab,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TelegramIcon from '@mui/icons-material/Telegram';
import {getRestaurants, newRestaurantRequestEmailSent,} from "../../actions/Actions";
import {useDispatch, useSelector} from "react-redux";
import {deepOrange, green, grey, red} from "@mui/material/colors";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import PrivatAgreement from "../pc/policies/PrivacyAgreement";
import {Mood, MoodBad} from "@mui/icons-material";
import Button from "@mui/material/Button";

const defaultValues = {
    user_phone: "",
};


const schema = yup.object().shape({
    user_phone: yup.string().required("Обязательное поле"),
});

const Form = () => {
    //Load data from api
    const dispatch = useDispatch();

    // Get values from form
    const [formValues, setFormValues] = useState(defaultValues);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    // Form validation
    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema),
    });

    //privat policy
    const [checked, setChecked] = React.useState(false);
    const handleChek = (event) => {
        setChecked(event.target.checked);
        const {name, checked} = event.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const onSubmitHandler = async () => {
        const BOT_TOKEN = '6225092718:AAFdqySsabUX93ejwpuy2SBF6LAe4ZbJHoI';
        const CHAT_ID = '-946060882';

        const url = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;

        try {
            const response = await axios.post(url, {
                chat_id: CHAT_ID,
                text: `Новая заявка на подключение\n\nТелефон: ${formValues['user_phone']}`,
            });

            console.log(response.data);
            alert('Заявка на подключение создана 🥳🙏 \n' +
                'Мы свяжемся с вами в ближайшее время.')
            window.location.reload()
        } catch (error) {
            console.error(error);
        }
    };

    // const onSubmitHandler = async () => {
    //     const response = await dispatch(newRestaurantRequestEmailSent({
    //         message: `
    //         <ul>
    //             <li>
    //                 Телефон: ${formValues['user_phone']}
    //             </li>
    //         </ul>
    //      `,
    //         subject: "Заявка на подключение"
    //     }));
    //     console.log(response)
    //     {
    //         response === 'Message send' &&
    //         alert('Заявка на подключение создана 🥳🙏 \n' +
    //             'Мы свяжемся с вами в ближайшее время.')
    //     }
    //     {
    //         response === 'Message send' &&
    //         setFormValues(defaultValues)
    //         reset()
    //     }
    //     {
    //         response != 'Message send' &&
    //         alert('Что пошло не так ⛔🥴\n' +
    //             'Напишите в службу поддержки https://t.me/boost_boox_support\n' +
    //             'Или попробуйте отправить заявку еще раз')
    //     }
    // };

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmitHandler)}
            >
                <Grid container spacing={3} alignItems="left" justify="center" direction="row">
                    {/*    <Grid item xs={12} md={12}>*/}
                    {/*        <Paper*/}
                    {/*            variant={"outlined"}*/}
                    {/*            style={{*/}
                    {/*                textAlign: "center",*/}
                    {/*                borderTopLeftRadius: 10,*/}
                    {/*                borderTopRightRadius: 10,*/}
                    {/*                // marginTop: 10,*/}
                    {/*                background: grey[50]*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Typography variant={"overline"}>*/}
                    {/*                Данные администратора*/}
                    {/*            </Typography>*/}
                    {/*        </Paper>*/}
                    {/*    </Grid>*/}
                    <Grid item xs={12} md={12}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextField
                                {...register('user_phone')}
                                sx={{width: "100%"}}
                                id="user_phone-input"
                                name="user_phone"
                                label="Номер телефона"
                                type="text"
                                variant={"standard"}
                                value={formValues.user_phone}
                                onChange={handleInputChange}
                                error={errors.user_phone?.message}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position='end'>
                                //             <Tooltip
                                //                 title={"Этот номер телефона будет использоваться как логин для входа в систему"}>
                                //                 <ButtonBase style={{borderRadius: 30}}>
                                //                     <HelpOutlineIcon style={errors.user_phone?.message && {
                                //                         background: green[900],
                                //                         borderRadius: 30,
                                //                         color: "white"
                                //                     }} fontSize={"small"}/>
                                //                 </ButtonBase>
                                //             </Tooltip>
                                //         </InputAdornment>
                                //     ),
                                // }}
                                helperText={errors.user_phone?.message}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {/*<Grid style={{marginTop: 50}} item xs={12} md={12}>*/}
                {/*    <div*/}
                {/*    style={{*/}
                {/*        display: "flex",*/}
                {/*    }}*/}
                {/*    >*/}
                {/*        <Typography style={{fontSize: 6, marginTop: 20}} variant={"overline"}>*/}
                {/*            Если у вас возникли проблемы или вы хотите задать вопрос службе поддержки, пожалуйста,*/}
                {/*            напишите нам*/}
                {/*        </Typography>*/}
                {/*        <TelegramIcon style={{marginRight: 3, color: "#0088cc"}}/>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
                <div style={{textAlign: "right", marginTop: 30, marginBottom: 20}}>
                    <Button variant="outlined" size={"small"} color="primary" type="submit">
                        Отправить
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default Form;