import {GET_RESTAURANTS,} from "../actions/types";

const initialState = {
    RestaurantsList: [],
};

const RestaurantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RESTAURANTS:
            return {
                ...state,
                RestaurantsList: action.payload,
            };
        default:
            return state;
    }
};

export default RestaurantsReducer;
