import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import {Container, Dialog, DialogActions, DialogContent, IconButton, useMediaQuery} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
// import RegistrationForm from "../pc/org_registration/RegisterOrg";
import RegistrationForm from "../pc/telegram";
import {useTheme} from "@emotion/react";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import phoneScreenImage from "../pc/img/phoneScreen.png";
import someFoodImage from "./img/someFoodImage.png";
import howItLooksImage from "./img/howItLooks.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {grey} from "@mui/material/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MainPage() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Container id={"home"} maxWidth={"lg"}>
                <div style={{height: 119}}/>
                <div >
                    <Typography
                        style={{
                            fontSize: 30,
                            lineHeight: 1,
                            // marginTop: 119,
                            // fontFamily: 'Inter',
                        }}
                    >
                        Бесконтактное электронное nfc-меню для ресторанов🍽️
                    </Typography>
                    <Typography variant={"body1"} style={{marginTop: 32, fontSize: 18}}>
                        Создайте онлайн меню для своего кафе, ресторана, службы доставки или room service. Вносите легко
                        любые изменения. Принимайте заказ без помощи персонала.
                    </Typography>
                    <Grid container spacing={1}
                          style={{
                              marginTop: 24,
                          }}
                    >
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={handleClickOpen}
                                style={{
                                    borderRadius: 14,
                                    paddingInline: 24,
                                    height: 52,
                                    background: "#5C61E9",
                                    color: "white",
                                    textTransform: "initial",
                                    fontSize: 18
                                }} variant={"extended"} color={"primary"}>
                                Подключиться бесплатно
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://bb.boost-box.ru"
                                style={{
                                    borderRadius: 14,
                                    paddingInline: 24,
                                    height: 52,
                                    background: grey[100],
                                    color: "black",
                                    textTransform: "initial",
                                    fontSize: 18
                                }} variant={"extended"} size={"large"} color={"primary"}>
                                Посмотреть пример меню
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <div id={"advantages"}>
                    <Grid container spacing={2} style={{marginTop: 184}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box style={{
                                background: "#5C61E9",
                                borderRadius: 24,
                            }}>
                                <div style={{height: 14}}/>
                                <Typography style={{marginInline: 24, fontSize: 28, color: "white"}}>
                                    Cократите расходы
                                </Typography>
                                <Typography style={{marginTop: 330, marginInline: 24, fontSize: 18, color: "white"}}>
                                    На печатную продукцию <br/>
                                    и персонал.
                                </Typography>
                                <div style={{height: 24}}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Box style={{
                                background: "#EEEBE1",
                                borderRadius: 24,
                            }}>
                                <div style={{height: 14}}/>
                                <Typography style={{marginInline: 24, fontSize: 28, color: "black"}}>
                                    Улучшите сервис
                                </Typography>
                                <Typography style={{marginTop: 330, marginInline: 24, fontSize: 18, color: "black"}}>
                                    Быстрый приём заказа без ошибок и ожидания официанта.
                                </Typography>
                                <div style={{height: 24}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box style={{
                                background: "#F5F5F7",
                                borderRadius: 24,
                            }}>
                                <div style={{height: 14}}/>
                                <Typography style={{marginInline: 24, fontSize: 28, color: "black"}}>
                                    Легко пользоваться
                                </Typography>
                                <Typography style={{marginTop: 330, marginInline: 24, fontSize: 18, color: "black"}}>
                                    Вносите изменения в меню со смартфона в любое время.
                                </Typography>
                                <div style={{height: 24}}/>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <div id={"howTiLooks"}
                 style={{
                     background: "#EEEBE1",
                     marginTop: 200
                 }}
            >
                <Box style={{
                    marginInline: 16,
                }}>
                    <Box
                        component="img"
                        sx={{
                            height: "100%",
                            width: "100%",
                        }}
                        style={{marginBottom: 20, marginTop: 24, borderRadius: 18}}
                        alt="The house from the offer."
                        src={howItLooksImage}
                    />
                    <Typography style={{fontSize: 28}}>
                        Онлайн меню не нужно печатать ✨
                    </Typography>
                    <Typography style={{fontSize: 18, marginTop: 24}}>
                        Ваше меню всегда будет актуальным и легко редактируемым, не изнашиваясь.
                    </Typography>
                    <div style={{height: 24}}/>
                </Box>
            </div>
            <div id={"benefits"}
                 style={{
                     // background: "#EEEBE1",
                     marginTop: 200
                 }}
            >
                <Box style={{
                    marginInline: 16,
                }}>
                    <Box
                        component="img"
                        sx={{
                            height: "100%",
                            width: "100%",
                        }}
                        style={{marginBottom: 20, borderRadius: 18}}
                        alt="The house from the offer."
                        src={someFoodImage}
                    />
                    <Typography style={{fontSize: 28}}>
                        Нанимайте меньше персонала ☁
                    </Typography>
                    <Typography style={{fontSize: 18, marginTop: 24}}>
                        Гости самостоятельно закажут еду со смартфона без помощи официанта. Обслуживание будет быстрее и
                        без ошибок.
                    </Typography>
                </Box>
            </div>
            {/*<div id={"savings"}*/}
            {/*     style={{*/}
            {/*         // background: "#EEEBE1",*/}
            {/*         marginTop: 200,*/}
            {/*     }}*/}
            {/*>*/}
            {/*    <Container style={{marginTop: 20}} maxWidth={"lg"}>*/}
            {/*        <Typography style={{fontSize: 32}}>*/}
            {/*            Улучшаем экономику бизнеса*/}
            {/*        </Typography>*/}
            {/*        <div style={{*/}
            {/*            textAlign: "center"*/}
            {/*        }}>*/}
            {/*            <Typography style={{fontSize: 96, fontWeight: "bold", color: "#5C61E9"}}>*/}
            {/*                ~ 30%*/}
            {/*            </Typography>*/}
            {/*        </div>*/}
            {/*        <Typography style={{fontSize: 18, color: "#5C61E9"}}>*/}
            {/*            Экономия на персонале и печати новых меню*/}
            {/*        </Typography>*/}
            {/*    </Container>*/}
            {/*</div>*/}
            <div style={{height: 130}}/>
            <Container  id="pricing" maxWidth={"lg"}>
                <div style={{height: 94}}/>
                <div style={{
                    background: "#5C61E9",
                    borderRadius: 24,
                    // height: 1050
                }}
                >
                    <Grid container spacing={3}
                        // style={{marginTop: 200}}
                    >
                        <Grid item xs={12} md={12}
                              style={{
                                  textAlign: "center"
                              }}>
                            <Typography variant={"h1"}
                                        style={{
                                            fontSize: 28,
                                            color: "white",
                                            marginTop: 1,
                                            marginInline: 24
                                        }}>
                                Начните сейчас бесплатно
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{
                            // marginTop: 24
                        }}>
                            <Box style={{
                                background: "white",
                                backgroundSize: "contain",
                                marginInline: 16,
                                borderRadius: 25,
                                // height: 378
                                // height: "100vh"
                            }}>
                                <br/>
                                <Typography variant={"h1"}
                                            style={{fontSize: 18, marginInline: 16, color: "black", marginTop: 29}}>
                                    Максимальный сервис
                                </Typography>
                                <Typography variant={"h1"}
                                            style={{fontSize: 30, marginInline: 18, color: "#5C61E9", marginTop: 24}}>
                                    0₽
                                </Typography>
                                <Typography variant={"h1"}
                                            style={{fontSize: 18, marginInline: 18, color: "black", marginTop: 24}}>
                                    Редактируйте меню <br/>
                                    со смартфона и принимайте заказ без официантов
                                </Typography>

                                <div style={{display: 'inline-flex', marginInline: 18, marginTop: 29}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 18, color: "black", marginInline: 8}}>
                                        офлайн оплата 0%
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginInline: 18, marginTop: 9}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 18, color: "black", marginInline: 8}}>
                                        онлайн оплата ~4.5%
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginInline: 18, marginTop: 9}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 18, color: "black", marginInline: 8}}>
                                        онлайн-меню
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginInline: 18, marginTop: 9}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 18, color: "black", marginInline: 8}}>
                                        прием заказов онлайн
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginInline: 18, marginTop: 9}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 18, color: "black", marginInline: 8, marginBottom: 48}}>
                                        почтовые уведомления
                                        <br/>
                                        о новых заказах
                                    </Typography>
                                </div>
                                {/*<br/>*/}
                                {/*<div style={{display: 'inline-flex', marginInline: 18, marginTop: 9}}>*/}
                                {/*    <CheckCircleIcon/>*/}
                                {/*    <Typography variant={"h1"}*/}
                                {/*                style={{*/}
                                {/*                    fontSize: 18,*/}
                                {/*                    color: "black",*/}
                                {/*                    marginInline: 8,*/}
                                {/*                    marginBottom: 48*/}
                                {/*                }}>*/}
                                {/*        телеграм бот*/}
                                {/*    </Typography>*/}
                                {/*</div>*/}
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} style={{
                            // marginTop: 88
                        }}>
                            <Box style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "white",
                                backgroundSize: "contain",
                                marginInline: 16,
                                borderRadius: 25,
                                // height: 378
                            }}>
                                {/*<img style={{width: 362.43, height: 462.28, marginRight: 35,}} src={phoneScreenImage}*/}
                                {/*     alt="My Image"/>*/}
                                <Box
                                    component="img"
                                    sx={{
                                        height: 324,
                                        width: 256,
                                        maxHeight: {xs: 324, md: 324},
                                        maxWidth: {xs: 256, md: 256},
                                    }}
                                    style={{marginRight: 6, marginBottom: 48, marginTop: 48}}
                                    alt="The house from the offer."
                                    src={phoneScreenImage}
                                />
                            </Box>
                            <Typography style={{textAlign: "center", color: "white", marginTop: 32}}>
                                Настройте автоматизацию
                                <br/>
                                ресторана за 7 минут
                            </Typography>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <Button
                                    onClick={handleClickOpen}
                                    style={{
                                        width: "60%",
                                        marginTop: 32,
                                        marginBottom: 48,
                                        // marginLeft: 35,
                                        // marginRight: 35,
                                        borderRadius: 14,
                                        padding: 24,
                                        height: 38,
                                        background: "white",
                                        color: "black",
                                        textTransform: "initial"
                                    }} variant={"extended"} size={"large"} color={"primary"}>
                                    Оставить заявку
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Container style={{marginTop: 20}} maxWidth={"lg"}>
                <Dialog
                    open={open}
                    // fullScreen={fullScreen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogActions>
                        <Button color={"secondary"} size={"small"} autoFocus onClick={handleClose}>
                            <HighlightOffIcon/>
                        </Button>
                    </DialogActions>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <RegistrationForm/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Container>
        </div>
    );
}