import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RegistrationForm from "../telegram";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HowItWorks from "./HowItWorks";
import {
    Button,
    Container,
    Dialog, DialogActions,
    DialogContent,
    Typography,
    useMediaQuery
} from "@mui/material";
import phoneScreenImage from '../img/phoneScreen.png';
import Slide from "@mui/material/Slide";
import {useTheme} from "@emotion/react";
import DialogContentText from "@mui/material/DialogContentText";
import someFoodImage from "../img/someFood.png";
import howItLooksImage from "../img/howItLooks.png";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AboutUs() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Container maxWidth={"lg"}>
                <div id={"home"}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Typography variant={"h3"} style={{fontSize: 64,}}>
                        Бесконтактное электронное nfc-меню для ресторанов🍽️
                    </Typography>
                    <Typography variant={"body1"} style={{marginTop: 32, fontSize: 24}}>
                        Создайте онлайн меню для своего кафе, ресторана, службы доставки или room service. Вносите легко
                        любые изменения. Принимайте заказ без помощи персонала.
                    </Typography>
                    <Button
                        onClick={handleClickOpen}
                        style={{
                            marginTop: 32,
                            borderRadius: 14,
                            paddingInline: 24,
                            height: 52,
                            background: "#5C61E9",
                            color: "white",
                            textTransform: "lowercase"
                        }} variant={"extended"} size={"large"} color={"primary"}>
                        Подключиться бесплатно
                    </Button>
                    <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://bb.boost-box.ru"
                        style={{
                            marginLeft: 16,
                            marginTop: 32,
                            borderRadius: 14,
                            paddingInline: 24,
                            height: 52,
                            background: "white",
                            color: "black",
                            textTransform: "lowercase"
                        }} variant={"extended"} size={"large"} color={"primary"}>
                        Посмотреть пример меню
                    </Button>
                </div>
                <div id={"advantages"}>
                    <Grid container spacing={3} style={{marginTop: 140}}>
                        <Grid item xs={12} sm={12} md={4}>
                            <div style={{height: 421, width: 367, background: "#5C61E9", borderRadius: 24,}}>
                                <Typography style={{paddingTop: 24, marginLeft: 24, fontSize: 32, color: "white"}}>
                                    Cократите расходы
                                </Typography>
                                <Typography style={{marginTop: 250, marginLeft: 24, fontSize: 24, color: "white"}}>
                                    На печатную продукцию <br/>
                                    и персонал.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div style={{height: 421, width: 367, background: "#EEEBE1", borderRadius: 24}}>
                                <Typography style={{paddingTop: 24, marginLeft: 24, fontSize: 32}}>
                                    Улучшите сервис
                                </Typography>
                                <Typography style={{marginTop: 215, marginLeft: 24, fontSize: 24}}>
                                    Быстрый приём заказа без ошибок и ожидания официанта.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div style={{height: 421, width: 367, background: "#F5F5F7", borderRadius: 24}}>
                                <Typography style={{paddingTop: 24, marginLeft: 24, fontSize: 32}}>
                                    Легко пользоваться
                                </Typography>
                                <Typography style={{marginTop: 215, marginLeft: 24, fontSize: 24}}>
                                    Вносите изменения в меню со смартфона в любое время.
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <div style={{height: 630, background: "#EEEBE1", marginTop: 160}}>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6}>
                            <Typography style={{
                                fontSize: 32,
                                marginLeft: 24,
                                marginRight: 24,
                                marginTop: 219
                            }}>
                                Онлайн меню не нужно каждый раз печатать заново
                            </Typography>
                            <Typography style={{
                                fontSize: 18,
                                marginLeft: 24,
                                marginRight: 24,
                                marginTop: 24
                            }}>
                                Ваше меню всегда будет актуальным и легко редактируемым, не изнашиваясь.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Box
                                component="img"
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                }}
                                style={{marginBottom: 20, marginTop: 24, borderRadius: 32}}
                                src={howItLooksImage}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth={"lg"}>
                <div style={{
                    height: 630,
                    marginTop: 80
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6}>
                            <Box
                                component="img"
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                }}
                                style={{marginBottom: 20, marginTop: 24, borderRadius: 32}}
                                src={someFoodImage}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Typography style={{
                                fontSize: 32,
                                marginLeft: 24,
                                marginRight: 24,
                                marginTop: 219
                            }}>
                                Нанимайте меньше персонала
                            </Typography>
                            <Typography style={{
                                fontSize: 18,
                                marginLeft: 24,
                                marginRight: 24,
                                marginTop: 24
                            }}>
                                Гости самостоятельно закажут еду со смартфона без помощи официанта. Обслуживание будет
                                быстрее и без ошибок.
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Container>

            <div id={"howToStart"}>
                <HowItWorks/>
            </div>

            <Container maxWidth={"lg"}>
                <div id="pricing" style={{background: "#5C61E9", borderRadius: 24, height: 990}}>
                    <Grid container spacing={3} style={{marginTop: 140}}>
                        <Grid item xs={12} md={12}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                // height: "100vh"
                            }}>
                                <Typography variant={"h1"}
                                            style={{
                                                fontSize: 32,
                                                marginLeft: 24,
                                                color: "white",
                                                marginTop: 70
                                            }}>
                                    Начните сейчас бесплатно
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: 32}}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "white",
                                backgroundSize: "contain",
                                marginLeft: 35,
                                marginRight: 30,
                                borderRadius: 25,
                                height: 524
                                // height: "100vh"
                            }}>
                                <img style={{width: 362.43, height: 462.28, marginRight: 35,}} src={phoneScreenImage}
                                     alt="My Image"/>

                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: 32}}>
                            <div style={{
                                background: "white",
                                backgroundSize: "contain",
                                marginRight: 35,
                                marginLeft: 30,
                                borderRadius: 25,
                                height: 524
                                // height: "100vh"
                            }}>
                                <br/>
                                <br/>
                                <Typography variant={"h1"} style={{fontSize: 24, marginLeft: 35, color: "black"}}>
                                    Максимальный сервис
                                </Typography>
                                <Typography variant={"h1"}
                                            style={{fontSize: 34, marginLeft: 35, color: "#5C61E9", marginTop: 10}}>
                                    0₽
                                </Typography>
                                <Typography variant={"h1"}
                                            style={{fontSize: 18, marginLeft: 35, color: "black", marginTop: 10}}>
                                    Редактируйте меню со смартфона <br/>
                                    и принимайте заказ без официантов
                                </Typography>

                                <div style={{display: 'inline-flex', marginLeft: 35, marginTop: 39}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 14, color: "black", marginTop: 3, marginLeft: 28}}>
                                        комиссия офлайн оплата 0%
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginLeft: 35, marginTop: 16}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 14, color: "black", marginTop: 3, marginLeft: 28}}>
                                        комиссия онлайн оплата ~4.5%
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginLeft: 35, marginTop: 16}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 14, color: "black", marginTop: 3, marginLeft: 28}}>
                                        онлайн-меню
                                    </Typography>
                                </div>
                                <br/>
                                {/*<div style={{display: 'inline-flex', marginLeft: 35, marginTop: 16}}>*/}
                                {/*    <CheckCircleIcon/>*/}
                                {/*    <Typography variant={"h1"}*/}
                                {/*                style={{fontSize: 14, color: "black", marginTop: 3, marginLeft: 28}}>*/}
                                {/*        прием заказов онлайн*/}
                                {/*    </Typography>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                <div style={{display: 'inline-flex', marginLeft: 35, marginTop: 16}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 14, color: "black", marginTop: 3, marginLeft: 28}}>
                                        почтовые уведомления о новых заказах
                                    </Typography>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', marginLeft: 35, marginTop: 16}}>
                                    <CheckCircleIcon/>
                                    <Typography variant={"h1"}
                                                style={{fontSize: 14, color: "black", marginTop: 3, marginLeft: 28}}>
                                        телеграм бот
                                    </Typography>
                                </div>
                                <Button
                                    onClick={handleClickOpen}
                                    style={{
                                        width: "86.5%",
                                        marginTop: 32,
                                        marginLeft: 35,
                                        marginRight: 35,
                                        borderRadius: 14,
                                        paddingInline: 24,
                                        height: 38,
                                        background: "#5C61E9",
                                        color: "white",
                                        textTransform: "lowercase"
                                    }} variant={"extended"} size={"large"} color={"primary"}>
                                    Подключиться бесплатно
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center", marginTop: 34}}>
                            <Typography style={{fontSize: 28, color: "white"}}>
                                Настройте автоматизацию ресторана за 7 минут
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 0
                        }}>
                            <Button
                                onClick={handleClickOpen}
                                style={{
                                    borderRadius: 14,
                                    paddingInline: 24,
                                    height: 52,
                                    background: "white",
                                    color: "black",
                                    textTransform: "lowercase",
                                    fontSize: 18
                                }} variant={"extended"} size={"large"} color={"primary"}>
                                Оставить заявку
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogActions>
                    <Button color={"secondary"} size={"small"} autoFocus onClick={handleClose}>
                        <HighlightOffIcon/>
                    </Button>
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <RegistrationForm/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}
